<template>
  <router-view v-if="canAccessRoute" v-slot="{ Component, route }">
    <transition name="fade-transform" mode="out-in">
      <keep-alive :include="cachedViews">
        <component :is="Component" :key="route.path" class="component" />
      </keep-alive>
    </transition>
  </router-view>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { RouteName } from '@/ui/router/consts.js'

export default {
  name: 'AppMain',
  computed: {
    ...mapState({
      allowedRoutes: state => state.permission.routers,
    }),
    ...mapGetters({
      /** Requires user permissions to have been previously loaded */
      checkPermission: 'permission/checkPermission',
    }),
    cachedViews() {
      return this.$store.state.tagsView.cachedViews
    },
    canAccessRoute() {
      return this.checkPermission(this.$route.meta?.permissionName, this.$route.meta?.roles)
    },
  },
  created() {
    if (!this.canAccessRoute) this.$router.replace({ name: RouteName.NOT_FOUND })
  },
}
</script>

<style scoped lang="scss">
.component {
  height: 100%;
}
</style>
