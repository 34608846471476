<template>
  <div class="lo-navbar">
    <hamburger :toggle-click="toggleSideBar" :is-active="sidebar.opened" class="hamburger-container" />

    <breadcrumb class="breadcrumb-container" />

    <div class="right-menu">
      <template v-if="device!=='mobile'">
        <header-search class="right-menu-item" />
        <screenfull class="right-menu-item hover-effect" />
        <lang-select class="right-menu-item hover-effect" />
      </template>

      <div
        v-if="!isVisible"
        class="avatar-container right-menu-item hover-effect"
        @click="isVisible = true"
      >
        <div class="avatar-wrapper">
          <img v-if="avatar" :src="avatar" class="user-avatar">
          <icon v-else el-icon="ElIconUser" />
        </div>
      </div>

      <el-dropdown
        v-else
        ref="dropdown"
        class="avatar-container right-menu-item hover-effect"
        data-test="dropdown-avatar"
        trigger="click"
        placement="bottom-end"
        @visible-change="isVisible = $event"
      >
        <div class="avatar-wrapper">
          <img v-if="avatar" :src="avatar" class="user-avatar">
          <icon v-else el-icon="ElIconUser" />
        </div>
        <template #dropdown>
          <el-dropdown-menu class="dropdown">
            <router-link to="/user-preferences">
              <el-dropdown-item class="dropdown__item">
                User - {{ $store.getters['user/customUserData'].email }}
              </el-dropdown-item>
            </router-link>
            <router-link
              v-if="$hasPermission(['admin', 'accountAdmin'])"
              :to="{ name: RouteName.COMPANY_PROFILE }"
              class="link-type"
            >
              <el-dropdown-item class="dropdown__item">
                {{ `${i18n.t('basic.company')} - ${companyName || ''}` }}
              </el-dropdown-item>
            </router-link>
            <a target="_blank" href="https://docs.locoia.com">
              <el-dropdown-item class="dropdown__item" divided>
                {{ i18n.t('navbar.support') }}
              </el-dropdown-item>
            </a>
            <a target="_blank" href="https://status.locoia.com/">
              <el-dropdown-item class="dropdown__item">
                {{ i18n.t('navbar.systemstatus') }}
              </el-dropdown-item>
            </a>
            <el-dropdown-item class="dropdown__item" divided @click="logout">
              <span style="display:block;" data-test="logout">{{ i18n.t('navbar.logOut') }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { RouteName } from '@/ui/router/consts.js'
import Breadcrumb from './Breadcrumb'
import Hamburger from './Hamburger'
import LangSelect from './LangSelect'
import Screenfull from './Screenfull'
import HeaderSearch from './HeaderSearch'

export default {
  name: 'Navbar',
  components: {
    Breadcrumb,
    Hamburger,
    Screenfull,
    LangSelect,
    HeaderSearch,
  },
  inject: ['i18n'],
  data() {
    return {
      isVisible: false,
      RouteName,
    }
  },
  computed: {
    ...mapState({
      companyName: (state) => state.company?.company?.name,
      sidebar: (state) => state.app?.sidebar,
      device: (state) => state.app?.device,
    }),
    ...mapGetters({
      name: 'user/name',
      avatar: 'user/avatar',
    }),
  },
  watch: {
    isVisible() {
      this.$nextTick(() => {
        if (!this.isVisible) return
        this.$refs.dropdown.handleOpen()
      })
    },
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    async logout() {
      await this.$store.dispatch('user/LogOut')
      // In order to re-instantiate the vue-router object to avoid bugs.
      window.location.reload()
    },
  },
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.lo-navbar {
  height: 50px;
  overflow: hidden;
  .dropdown {
    max-width: 200px;
    &__item {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background .3s;

    &:hover {
      background: rgba(0, 0, 0, .025)
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      line-height: 50px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background .3s;

        &:hover {
          background: rgba(0, 0, 0, .025)
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }
      }
    }
  }

  .el-icon {
    font-size: 1.2em;
    line-height: 2.6em;
    display: flex;
  }
}
</style>
