import Layout from '@/ui/layout'
import { RouteName } from '@/ui/router/consts.js'

export default {
  path: '/dashboard',
  component: Layout,
  redirect: 'noredirect',
  meta: {
    title: 'myDashboards',
    icon: 'dashboard',
    permissionName: 'dashboard_view',
  },
  children: [
    {
      name: RouteName.DASHBOARD_TRANSFORMATION,
      path: 'transformation',
      component: () => import(/* webpackChunkName: "transformation" */'@/ui/views/Dashboards/Transformation'),
      meta: {
        title: 'transformation',
        icon: 'component',
        noCache: true,
        permissionName: 'dashboard_view',
      },
    },
    {
      name: RouteName.DASHBOARD_DATA_SOURCE,
      path: 'data-source',
      component: () => import(/* webpackChunkName: "dataSource" */'@/ui/views/Dashboards/DataSource'),
      meta: {
        title: 'dataSources',
        icon: 'list',
        noCache: true,
        permissionName: 'dashboard_view',
      },
    },
  ],
}
