import Layout from '@/ui/layout'
import { RouteName } from '@/ui/router/consts.js'

export default {
  path: '/help-and-doc-router',
  component: Layout,
  redirect: { name: RouteName.FLOW_BUILDER },
  meta: {
    title: 'help_docs',
    icon: 'example',
    data: [
      {
        path: 'https://docs.locoia.com/',
        meta: {
          title: 'documentation',
          icon: 'documentation',
          noCache: true,
        },
      },
      {
        path: 'https://www.youtube.com/channel/UC4WaSwSERFd3K-v7-sik4fw',
        meta: {
          title: 'YouTube',
          elIcon: 'ElIconVideoCamera',
          noCache: true,
        },
      },
      {
        path: '',
        meta: {
          title: 'Shortcuts',
          dialog: 'Shortcuts',
          elIcon: 'ElIconInfoFilled',
          event: 'shortcut',
          noCache: true,
        },
      },
      {
        path: 'https://join.slack.com/t/locoia-automation/shared_invite/zt-xnphvr1d-_eI0E9_1VGCndrBnFyRPzg',
        meta: {
          title: 'Community Slack',
          icon: 'peoples',
          noCache: true,
        },
      },
      {
        path: 'https://www.locoia.com',
        meta: {
          title: 'About us',
          elIcon: 'ElIconOfficeBuilding',
          noCache: true,
        },
      },
      {
        path: 'https://hubs.ly/Q01bf7tf0',
        meta: {
          title: 'GTCs',
          icon: 'documentation',
          noCache: true,
        },
      },
    ],
  },
}
