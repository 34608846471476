<template>
  <div class="sidebar" :class="{'has-logo':showLogo}">
    <sidebar-logo v-if="showLogo" :collapse="!isSidebarOpened" />
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <loadable-content :is-loading="!hasRoutes">
        <el-menu
          :default-active="defaultActive"
          background-color="#304156"
          text-color="#bfcbd9"
          active-text-color="#409EFF"
          :collapse="!isSidebarOpened"
        >
          <sidebar-menu
            :is-collapse="!isSidebarOpened"
            @event-click="openDialog = $event"
          />
        </el-menu>
      </loadable-content>
    </el-scrollbar>

    <flow-info-dialog
      v-if="openDialog === 'shortcut'"
      :on-close="() => openDialog = null"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import LoadableContent from '@/ui/components/LoadableContent/index.vue'
import FlowInfoDialog from '@/ui/views/FlowBuilder/dialogs/FlowInfoDialog'
import SidebarLogo from './SidebarLogo'
import SidebarMenu from './SidebarMenu'

export default {
  name: 'Sidebar',
  components: {
    LoadableContent,
    SidebarLogo,
    SidebarMenu,
    FlowInfoDialog,
  },
  data() {
    return {
      openDialog: null,
    }
  },
  computed: {
    ...mapState({
      allowedRoutes: state => state.permission.routers,
      isSidebarOpened: state => state.app.sidebar.opened,
      showLogo: state => state.settings.sidebarLogo,
    }),
    defaultActive() {
      return String(this.$route.name)
    },
    hasRoutes() {
      return this.allowedRoutes?.length > 0
    },
  },
}
</script>
<style lang="scss" scoped>
.sidebar {
  :deep(.el-scrollbar__view) {
    height: inherit;
  }
}
</style>
