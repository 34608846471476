import { createRouter, createWebHistory, isNavigationFailure, NavigationFailureType } from 'vue-router'
import Layout from '@/ui/layout'
import { RouteName } from '@/ui/router/consts.js'
import helpAndDocModule from './modules/helpAndDoc'
import settingsModule from './modules/settings'
import dashboardsModule from './modules/dashboards'
import embedModule from './modules/embed'
import hash from './navigationGuards/hash'
import pageTitle from './navigationGuards/pageTitle'
import permission from './navigationGuards/permission'
import progress from './navigationGuards/progress'

/**
 * name: 'routeName'              name of the route, required if you want to link to this route with <router-link>
 * meta : {
 *    roles: ['admin','user','accountOwner']    will control the page roles (you can set multiple roles)
 *    permissionName: 'flow_view' | 'embed_view' | 'connector_view'  will control the page access according to the
 *                                                                   user's views access settings
 *    title: 'title'               the name that will be displayed in sub-menus, breadcrumbs, and page title
 *    noCache: false               if true, the page will not be cached (TODO: what 'cached' means exactly?)
 *    hidden: false                if true, will not be suggested when searching with the header search
 *  }
 * */

export const constantRouterMap = [
  { // deprecated
    path: '/redirect',
    component: Layout,
    meta: {
      hidden: true,
    },
    children: [{
      path: '/redirect/:path*',
      component: () => import(/* webpackChunkName: "core" */'@/ui/views/Redirect'),
    }],
  },
  { // deprecated
    path: '/auth-redirect',
    component: () => import(/* webpackChunkName: "core" */'@/ui/views/AuthRedirect'),
    meta: {
      hidden: true,
    },
  },
  {
    name: RouteName.LOGIN,
    path: '/login',
    component: () => import(/* webpackChunkName: "login" */'@/ui/views/Login'),
    meta: {
      hidden: true,
    },
  },
  {
    name: RouteName.INDEX,
    path: '',
    component: Layout,
    redirect: { name: RouteName.FLOW_MONITORING },
    children: [{
      name: RouteName.FLOW_MONITORING,
      path: 'flow-monitoring',
      component: () => import(/* webpackChunkName: "flowMonitoring" */'@/ui/views/FlowMonitoring'),
      meta: {
        title: 'flowMonitoring',
        noCache: true,
        permissionName: 'flow_view',
      },
    }],
  },
  {
    path: '',
    component: Layout,
    children: [
      {
        path: 'flowbuilder',
        redirect: { name: RouteName.FLOWS },
      },
      {
        name: RouteName.FLOWS,
        path: 'flows',
        component: () => import(/* webpackChunkName: "flowBuilder" */'@/ui/views/Flows'),
        meta: {
          title: 'flows',
          noCache: true,
          permissionName: 'flow_view',
        },
      },
    ],
  },
  {
    path: '',
    component: Layout,
    meta: {
      hidden: true,
    },
    children: [
      {
        path: 'flowbuilder/:flowid',
        redirect: to => ({ name: RouteName.FLOW_BUILDER, params: { flowid: to.params.flowid }}),
      },
      {
        name: RouteName.FLOW_BUILDER,
        path: 'flows/:flowid/builder',
        component: () => import(/* webpackChunkName: "flowBuilder" */'@/ui/views/FlowBuilder'),
        meta: {
          title: 'flowBuilder',
          noCache: true,
          permissionName: 'flow_view',
          hidden: true,
        },
      },
    ],
  },
  {
    path: '',
    component: Layout,
    meta: {
      hidden: true,
    },
    children: [
      {
        path: 'flowdebugger/:flowid',
        redirect: to => ({ name: RouteName.FLOW_DEBUGGER, params: { flowid: to.params.flowid }}),
      },
      {
        name: RouteName.FLOW_DEBUGGER,
        path: 'flows/:flowid/debugger',
        component: () => import(/* webpackChunkName: "flowBuilder" */'@/ui/views/FlowDebugger'),
        meta: {
          title: 'flowdebugger',
          permissionName: 'flow_view',
          noCache: true,
          hidden: true,
        },
      },
    ],
  },
  {
    path: '',
    component: Layout,
    children: [{
      name: RouteName.COMMUNITY_LIBRARY,
      path: 'community-library',
      alias: 'flow-marketplace',
      component: () => import(/* webpackChunkName: "communityLibrary" */'@/ui/views/CommunityLibrary'),
      meta: {
        title: 'communityLibrary',
        permissionName: 'flow_view',
        noCache: true,
      },
    }],
  },
  {
    path: '',
    component: Layout,
    meta: {
      hidden: true,
    },
    children: [{
      name: RouteName.PROFILE,
      path: 'user-preferences',
      component: () => import(/* webpackChunkName: "profile" */'@/ui/views/Profile'),
      meta: {
        title: 'profile',
        noCache: true,
      },
    }],
  },
  {
    path: '',
    component: Layout,
    meta: {
      hidden: true,
    },
    children: [{
      name: RouteName.COMPANY_PROFILE,
      path: 'company-preferences',
      component: () => import(/* webpackChunkName: "companyPreference" */'@/ui/views/CompanyPreference'),
      meta: {
        title: 'companyProfile',
        roles: ['admin', 'accountAdmin'],
        noCache: true,
      },
    }],
  },
  {
    path: '',
    component: Layout,
    children: [{
      name: RouteName.CONNECTOR_AUTH,
      path: 'connector-auth',
      component: () => import(/* webpackChunkName: "settings" */'@/ui/views/ConnectorAuth'),
      meta: {
        title: 'connectorAuth',
        noCache: true,
      },
    }],
  },
  {
    path: '',
    component: Layout,
    children: [{
      name: RouteName.FILE_UPLOAD,
      path: 'file-uploader',
      component: () => import(/* webpackChunkName: "settings" */'@/ui/views/FileUpload'),
      meta: {
        title: 'fileUploader',
        noCache: true,
      },
    }],
  },
  {
    path: '',
    component: Layout,
    children: [{
      name: RouteName.ENV_VARIABLES,
      path: 'flow-env-variables',
      component: () => import(/* webpackChunkName: "settings" */'@/ui/views/EnvVariableList'),
      meta: {
        title: 'flowEnvVariables',
        noCache: true,
      },
    }],
  },
  {
    path: '',
    component: Layout,
    children: [{
      name: RouteName.ACTIVITY_LOG,
      path: 'activity-log',
      component: () => import(/* webpackChunkName: "settings" */'@/ui/views/ActivityLog'),
      meta: {
        title: 'activityLog',
        roles: ['admin', 'accountAdmin'],
        noCache: true,
      },
    }],
  },
  embedModule,
  dashboardsModule,
  settingsModule,
  helpAndDocModule,
  {
    path: '',
    component: Layout,
    meta: {
      hidden: true,
    },
    children: [{
      name: RouteName.NOT_FOUND,
      path: '/404',
      component: () => import(/* webpackChunkName: "core" */'@/ui/views/404'),
      meta: {
        hidden: true,
      },
    }],
  },
  {
    path: '',
    component: Layout,
    meta: {
      hidden: true,
    },
    children: [{
      name: RouteName.UNAUTHORIZED,
      path: '/401',
      component: () => import(/* webpackChunkName: "core" */'@/ui/views/401'),
      meta: {
        hidden: true,
      },
    }],
  },
  {
    path: '/:catchAll(.*)*',
    redirect: '404',
    meta: {
      hidden: true,
    },
  },
]

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior: () => ({ top: 0 }),
  routes: constantRouterMap,
})

// https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
const originalPush = router.push
router.push = (to) => originalPush.call(router, to).catch((err) => {
  if (isNavigationFailure(err, NavigationFailureType.redirected)) return
  throw err
})

progress(router)
pageTitle(router)
hash(router)
permission(router)

export default router
