export default {
  action: {
    actionDeleted: 'Die zuvor für diesen Schritt ausgewählte Aktion muss gelöscht worden sein',
    actionDoesNotExist: 'Aktion existiert nicht',
    run: 'Schritt ausführen',
    show_last_node_run: 'Letzen Flow Run anzeigen',
    create_auth: 'Neue Authentifizierung für Connector erstellen',
    add_auth_instruction: 'Klicken Sie auf "Hinzufügen", um eine neue Connector Authentifizierung zu erstellen oder geben Sie eine dynamische Connector Authentifizierung-ID ein',
    // Boolean
    comparison: 'Boolean-Vergleich durchführen',
    // Delay Helper
    delaying: 'Flow Ausführung verzögern',
    // Data Warehouse Helper
    insert_or_update: 'Daten einfügen oder updaten',
    retrieve_from: 'Daten abrufen',
    // File Storage
    publish: 'Upload und publish Dateien (öffentlich zugänglich)',
    store_private_file: 'Datei für Nutzung in Flows speichern (privat)',
    retrieve_private_file: 'Gespeicherte Datei für Nutzung in Flows abrufen (privat)',
    // JSON Helper
    read_json: 'JSON-Datei herunterladen und parsen',
    write_json: 'JSON-Datei speichern, um sie in einem Flow zu verwenden',
    // Multi flow trigger
    trigger_flows: 'Mehrere Flows anstoßen (triggern)',
    // CSV
    readcsv: 'CSV-Datei von URL einlesen',
    writecsv: 'CSV-Datei erstellen',
    parsecsv: 'Parse ein CSV von einem CSV Text-String',
    // Looper
    iterate_while: 'Iterate While Loop',
    iterate_over: 'Iterate Over Loop',
    run_all_iterations: 'Alle Iterationen ausführen',
    run_iteration: 'Durchlaufschritt mit Iteration ausführen:',
    set_index: 'Index setzen',
    specify_iteration_index: 'Sie können auch direkt einen spezifischen Iterationsindex eingeben',
    // FTP
    read_file: 'Datei einlesen',
    upload_file: 'Datei hochladen',
    delete_file: 'Datei löschen',
    list_directory: 'Dateien und Ordner auflisten',
    move_file: 'Datei in anderen Ordner verschieben',
    // Rest
    rest_client_get: 'GET Request',
    rest_client_post: 'POST Request',
    rest_client_put: 'PUT Request',
    rest_client_patch: 'PATCH Request',
    rest_client_delete: 'DELETE Request',
    // XML
    dict_to_xml: 'Dictionary in XML konvertieren',
    xml_to_dict: 'XML in Dictionary konvertieren',
    // Dict
    replace_field: 'String in Liste oder Dictionary ersetzen',
    add_field_to_dict: 'String in Dictionary hinzufügen',
    filter_list: 'Filtern einer list',
    append_field_to_list: 'Feld in Liste hinzufügen',
    set_variables: 'Variablen definieren zur späteren Verwendung z.B. JSON',
    flatten_list: 'Liste von Listen entfernen (`flatten`)',
    // Select Case
    select_case: 'Select Case Entscheidungsbaum',
    // Zip
    zip: 'Datei(en) zippen',
    unzip: 'ZIP Datei(en) entpacken',
    ungzip: 'gzip Datei(en) entpacken',
    // Scheduler
    schedule_flows: 'Flow Ausführung Planen',
    // Mail
    send_email: 'E-Mail versenden',
    // Spreadsheet Helper,
    group_by: 'Spalten gruppieren nach...',
    sort_by: 'Spalten sortieren nach...',
    // SQL
    execute: 'SQL das auszuführen ist',
    // Terminate
    termination: 'Flow Branch beenden',
    synchronizeSuperActionFlowCopies: 'Sync flow copies',
    webhook: {
      payload: 'Webhook Payload',
    },
  },
  auth: {
    allStepsUpdated: 'Alle "{connectorName}" Schritte wurden aktualisiert',
    setAllAuths: 'Setzen Sie alle Authentifizierungen von "{connectorName}" in diesem Ablauf auf "{currentAuthName}"',
  },
  connector: 'Connector',
  connectorAuths: 'Connector Auths',
  panel: {
    apply: 'Änderung übernehmen!',
    pleaseApplyAndSave: 'Bitte klicken Sie auf "Änderung übernehmen" und speichern Sie den Ablauf, damit Ihre Änderungen wirksam werden.',
  },
}
