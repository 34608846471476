<template>
  <div v-loading="!company" class="lo-sidebar-logo" :class="{'collapse':collapse}">
    <router-link :key="collapse ? 'collapse' : 'expand'" class="sidebar-logo-link" :to="{ name: RouteName.COMPANY_PROFILE }">
      <div class="sidebar-logo" :style="`background-image: url(${company?.image_url || logo}); min-width: ${collapse ? '100%' : '50px'}`" />
      <h1 class="sidebar-title">
        {{ company?.name }}
      </h1>
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import logo from '@/ui/assets/images/locoia-icon-96x96.png'
import { RouteName } from '@/ui/router/consts.js'

export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    logo,
    RouteName,
  }),
  computed: {
    ...mapGetters({
      name: 'user/name',
      customUserData: 'user/customUserData',
      avatar: 'user/avatar',
      roles: 'user/roles',
      company: 'company/getCompany',
    }),
  },
}
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.lo-sidebar-logo {
  position: relative;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background-color: #2b2f3a;
  text-align: center;
  overflow: hidden;

  & .sidebar-logo-link {
    display: flex !important;
    flex-wrap: nowrap;
    height: 100%;
    width: 100%;

    & .sidebar-title {
      flex: 1;
      overflow: hidden;
      padding: 0px;
      text-overflow: ellipsis;
      display: inline-block;
      margin: 0;
      color: #fff;
      font-weight: 600;
      line-height: 50px;
      font-size: 14px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }
  }

  .sidebar-logo {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    // min-width: 50px; set in template
  }
}
</style>
