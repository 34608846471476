import * as R from 'ramda'
import { connectorApi } from '@/ui/api/connector'
import { connectorActionApi } from '@/ui/api/connector-action'
import { loadAllResourcePages } from '@/ui/utils/request'

const byFields = orderBy => (left, right) => {
  const leftField = left[orderBy.field]
  const rightField = right[orderBy.field]
  let result
  if (leftField < rightField) result = -1
  if (leftField === rightField) result = 0
  if (leftField > rightField) result = 1
  return orderBy.order === 'ascending' ? result : -result
}

export default {
  namespaced: true,
  state: () => ({
    loadingConnectors: false,
    connectorIds: [],
    totalConnectors: 0,
    connectors: {},
    cached: false,
    functionDetails: null,
    functionDetailsPromise: null,
  }),
  getters: {
    connectorsArray: (state) => R.props(state.connectorIds, state.connectors),
    getConnectorsWithAuthConfig: (state, getters) => () => {
      const someAuthKeySet = (authConfig) => (
        authConfig.oauth1 ||
        authConfig.oauth2 ||
        authConfig.basic_auth ||
        authConfig.username_password ||
        authConfig.aws_request_signing ||
        authConfig.refreshable_token ||
        authConfig.private_ssh_key
      )
      const hasAuthConfig = (connector) => connector.auth_config && someAuthKeySet(connector.auth_config)

      const listParams = {
        orderBy: { field: 'name', order: 'ascending' },
        filter: hasAuthConfig,
      }
      return getters.list(listParams).connector
    },
    list: (state, getters) => ({
      page = 1, perPage, orderBy, filter,
    } = {}) => {
      const result = {
        connector: getters.connectorsArray,
        meta: {
          total: 0,
        },
      }

      if (filter) {
        result.connector = result.connector.filter(filter)
      }

      if (orderBy) {
        result.connector = result.connector.sort(byFields(orderBy))
      }

      result.meta.total = result.connector.length

      if (perPage) {
        const maxPage = Math.ceil(result.meta.total / perPage)
        const currentPage = maxPage < page ? 1 : page
        result.connector = result.connector.slice((currentPage - 1) * perPage, currentPage * perPage)

        if (currentPage === 1) {
          result.goToFirstPage = true
        }
      }

      return result
    },
    getById: (state) => (connectorId) => state.connectors[connectorId],
  },
  actions: {
    async fetchConnectorsNonPaginated({ commit }, options) {
      commit('SET_LOADING_CONNECTORS', true)
      const items = []
      const addItems = (newItems) => items.push(...newItems)
      await loadAllResourcePages(connectorApi.list, addItems, options)
      commit('SET_CONNECTORS', { data: items, meta: { total: items.length }})
      commit('SET_LOADING_CONNECTORS', false)
    },
    async fetchConnectorsCached({ state, commit, dispatch }, forceUpdate) {
      if (state.cached && !forceUpdate) return

      await dispatch('fetchConnectorsNonPaginated', {
        embed: ['rights'],
        sort: [['order_rank', 'asc'], ['created_date', 'asc']],
        extra: { authConfigNew: '1' },
      })

      commit('SET_CACHED', true)
    },
    async addConnector({ commit }, newData) {
      const { data } = await connectorApi.create(newData)
      commit('ADD_CONNECTOR', data)
    },
    async updateConnector({ commit }, newData) {
      const { data } = await connectorApi.update(newData)
      commit('UPDATE_CONNECTOR', data)
    },
    async copyConnector({ commit }, { connector, params }) {
      const { data } = await connectorApi.copy(connector, params)
      commit('ADD_CONNECTOR', data)
    },
    async deleteConnector({ commit }, connector) {
      await connectorApi.delete(connector)
      commit('DELETE_CONNECTOR', connector)
    },
    async fetchFunctionDetails({ commit }) {
      const { data: { suggestions }} = await connectorApi.getFunctionDetails()
      commit('SET_FUNCTION_DETAILS', suggestions)
    },
    async fetchFunctionDetailsCached({ state, commit, dispatch }) {
      if (state.functionDetails) return
      if (!state.functionDetailsPromise) commit('SET_FUNCTION_DETAILS_PROMISE', dispatch('fetchFunctionDetails'))
      await state.functionDetailsPromise
    },
    async initializeConnectorActions({ state, dispatch }, connectorId) {
      const connector = state.connectors[connectorId]

      const hasAlreadyLoadedActions = !R.isNil(connector.actions)
      if (hasAlreadyLoadedActions) return

      await dispatch('fetchConnectorActions', connectorId)
    },
    async fetchConnectorActions({ commit }, connectorId) {
      const connectorActions = []
      const addActions = (actions) => connectorActions.push(...actions)

      const options = { filters: [['connector_id', 'eq', connectorId]] }
      await loadAllResourcePages(connectorActionApi.list, addActions, options)

      commit('SET_CONNECTOR_ACTIONS', { connectorId: connectorId, connectorActions })
    },
  },
  mutations: {
    SET_LOADING_CONNECTORS(state, loading) {
      state.loadingConnectors = loading
    },
    SET_CONNECTORS(state, data) {
      const ids = new Set()
      data.data.forEach((entity) => {
        ids.add(entity.id)
        state.connectors[entity.id] = entity
      })
      state.connectorIds = [...ids]
      state.totalConnectors = data.meta.total
    },
    SET_CACHED: (state, data) => {
      state.cached = data
    },
    ADD_CONNECTOR: (state, data) => {
      state.connectorIds.push(data.id)
      state.connectors[data.id] = data
      state.totalConnectors++
    },
    UPDATE_CONNECTOR: (state, data) => {
      state.connectors[data.id] = data
    },
    DELETE_CONNECTOR: (state, data) => {
      delete state.connectors[data.id]
      state.connectorIds = R.reject(R.equals(data.id), state.connectorIds)
      state.totalConnectors--
    },
    SET_FUNCTION_DETAILS: (state, suggestions) => {
      state.functionDetails = suggestions
    },
    SET_FUNCTION_DETAILS_PROMISE: (state, promise) => {
      state.functionDetailsPromise = promise
    },
    SET_CONNECTOR_ACTIONS: (state, { connectorId, connectorActions }) => {
      state.connectors[connectorId].actions = connectorActions
    },
  },
}
