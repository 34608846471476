import Layout from '@/ui/layout'
import { RouteName } from '@/ui/router/consts.js'

export default {
  path: '/settings',
  component: Layout,
  redirect: 'noredirect',
  meta: {
    title: 'settings',
    icon: 'form',
  },
  children: [
    {
      path: 'connector',
      redirect: { name: RouteName.CONNECTOR },
      meta: {
        title: 'connector',
        icon: 'international',
        noCache: true,
        permissionName: 'connector_view',
      },
      children: [
        {
          name: RouteName.CONNECTOR,
          path: '',
          component: () => import(/* webpackChunkName: "settings" */'@/ui/views/Settings/Connector'),
          meta: {
            noCache: true,
            permissionName: 'connector_view',
          },
        },
        {
          name: RouteName.CONNECTOR_ACTIONS,
          path: ':connectorId/action',
          component: () => import(/* webpackChunkName: "settings" */'@/ui/views/Settings/ConnectorAction'),
          meta: {
            title: 'connectorActions',
            noCache: true,
            permissionName: 'connector_view',
            hidden: true,
          },
        },
      ],
    },
    {
      name: RouteName.USERS,
      path: 'user',
      component: () => import(/* webpackChunkName: "settings" */'@/ui/views/Settings/User'),
      meta: {
        title: 'user',
        roles: ['admin', 'accountAdmin'],
        icon: 'user',
        noCache: true,
      },
    },
    {
      name: RouteName.TEAMS,
      path: 'team',
      component: () => import(/* webpackChunkName: "settings" */'@/ui/views/Settings/Team'),
      meta: {
        title: 'team',
        roles: ['admin', 'accountAdmin'],
        icon: 'peoples',
        noCache: true,
      },
    },
    {
      name: RouteName.COMPANY,
      path: 'company',
      component: () => import(/* webpackChunkName: "settings" */'@/ui/views/Settings/Company'),
      meta: {
        title: 'company',
        roles: ['admin'],
        elIcon: 'ElIconOfficeBuilding',
        noCache: true,
      },
    },
    {
      name: RouteName.ROLES,
      path: 'roles',
      component: () => import(/* webpackChunkName: "settings" */'@/ui/views/Settings/Roles'),
      meta: {
        title: 'roles',
        roles: ['admin'],
        elIcon: 'ElIconLock',
        noCache: true,
      },
    },
  ],
}
