<template>
  <sidebar-menu-item
    :is-menu-collapsed="isCollapse"
    :title="generateTitle('flowMonitoring')"
    route-name="FlowMonitoring"
  >
    <template #icon><icon icon="chart" /></template>
  </sidebar-menu-item>

  <sidebar-menu-item
    :is-menu-collapsed="isCollapse"
    :title="generateTitle('flows')"
    route-name="Flows"
  >
    <template #icon><icon icon="tree" /></template>
  </sidebar-menu-item>

  <sidebar-menu-item
    :is-menu-collapsed="isCollapse"
    :title="generateTitle('communityLibrary')"
    route-name="CommunityLibrary"
  >
    <template #icon><icon icon="shopping" /></template>
  </sidebar-menu-item>

  <sidebar-menu-item
    :is-menu-collapsed="isCollapse"
    :title="generateTitle('connectorAuth')"
    route-name="ConnectorAuth"
  >
    <template #icon><icon icon="password" /></template>
  </sidebar-menu-item>

  <sidebar-menu-item
    :is-menu-collapsed="isCollapse"
    :title="generateTitle('fileUploader')"
    route-name="FileUpload"
  >
    <template #icon><icon icon="table" /></template>
  </sidebar-menu-item>

  <sidebar-menu-item
    :is-menu-collapsed="isCollapse"
    :title="generateTitle('flowEnvVariables')"
    route-name="EnvVariables"
  >
    <template #icon><icon el-icon="ElIconSwitch" /></template>
  </sidebar-menu-item>

  <sidebar-menu-item
    :is-menu-collapsed="isCollapse"
    :title="generateTitle('activityLog')"
    route-name="ActivityLog"
  >
    <template #icon><icon icon="excel" /></template>
  </sidebar-menu-item>

  <el-sub-menu
    v-show="$hasPermission(['embed_view'])"
    index="Embedding"
    class="submenu-item"
    @click="goToRouteByName('Embedding')"
  >
    <template #title>
      <icon el-icon="ElIconGrid" />
      <span v-if="!isCollapse"> {{ generateTitle('embed') }}</span>
    </template>
    <el-menu-item-group>
      <sidebar-menu-item
        :is-menu-collapsed="false"
        :title="generateTitle('configurations')"
        route-name="Embedding"
        class="submenu-item"
      >
        <template #icon><icon icon="list" /></template>
      </sidebar-menu-item>

      <sidebar-menu-item
        :is-menu-collapsed="false"
        :title="generateTitle('integrations')"
        route-name="EmbeddingIntegrations"
        class="submenu-item"
      >
        <template #icon><icon el-icon="ElIconSetUp" /></template>
      </sidebar-menu-item>

      <sidebar-menu-item
        :is-menu-collapsed="false"
        :title="generateTitle('connectorAuth')"
        route-name="EmbeddingConnectorAuths"
        class="submenu-item"
      >
        <template #icon><icon icon="password" /></template>
      </sidebar-menu-item>

      <sidebar-menu-item
        :is-menu-collapsed="false"
        :title="generateTitle('activityLog')"
        route-name="EmbeddingActivityLog"
        class="submenu-item"
      >
        <template #icon><icon icon="excel" /></template>
      </sidebar-menu-item>
    </el-menu-item-group>
  </el-sub-menu>

  <el-sub-menu
    v-show="$hasPermission(['dashboard_view'])"
    index="Dashboards"
    class="submenu-item"
  >
    <template #title>
      <icon icon="dashboard" />
      <span v-if="!isCollapse"> {{ generateTitle('myDashboards') }}</span>
    </template>

    <el-menu-item-group>
      <sidebar-menu-item
        :is-menu-collapsed="false"
        :title="generateTitle('transformation')"
        :route-name="RouteName.DASHBOARD_TRANSFORMATION"
        class="submenu-item"
      >
        <template #icon><icon icon="component" /></template>
      </sidebar-menu-item>

      <sidebar-menu-item
        :is-menu-collapsed="false"
        :title="generateTitle('dataSources')"
        :route-name="RouteName.DASHBOARD_DATA_SOURCE"
        class="submenu-item"
      >
        <template #icon><icon icon="list" /></template>
      </sidebar-menu-item>
    </el-menu-item-group>
  </el-sub-menu>

  <el-sub-menu
    v-show="$hasPermission(['connector_view', 'admin', 'accountAdmin'])"
    index="Settings"
    class="submenu-item"
  >
    <template #title>
      <icon icon="form" />
      <span v-if="!isCollapse"> {{ generateTitle('settings') }}</span>
    </template>

    <el-menu-item-group>
      <sidebar-menu-item
        v-show="$hasPermission(['connector_view'])"
        :is-menu-collapsed="false"
        :title="generateTitle('connector')"
        route-name="Connector"
        class="submenu-item"
      >
        <template #icon><icon icon="international" /></template>
      </sidebar-menu-item>

      <sidebar-menu-item
        v-show="$hasPermission(['admin', 'accountAdmin'])"
        :is-menu-collapsed="false"
        :title="generateTitle('user')"
        route-name="Users"
        class="submenu-item"
      >
        <template #icon><icon icon="user" /></template>
      </sidebar-menu-item>

      <sidebar-menu-item
        v-show="$hasPermission(['admin', 'accountAdmin'])"
        :is-menu-collapsed="false"
        :title="generateTitle('team')"
        route-name="Teams"
        class="submenu-item"
      >
        <template #icon><icon icon="peoples" /></template>
      </sidebar-menu-item>

      <sidebar-menu-item
        v-show="$hasPermission(['admin'])"
        :is-menu-collapsed="false"
        :title="generateTitle('company')"
        route-name="Company"
        class="submenu-item"
      >
        <template #icon><icon el-icon="ElIconOfficeBuilding" /></template>
      </sidebar-menu-item>

      <sidebar-menu-item
        v-show="$hasPermission(['admin'])"
        :is-menu-collapsed="false"
        :title="generateTitle('roles')"
        route-name="Roles"
        class="submenu-item"
      >
        <template #icon><icon el-icon="ElIconLock" /></template>
      </sidebar-menu-item>
    </el-menu-item-group>
  </el-sub-menu>

  <el-sub-menu index="Help" class="submenu-item">
    <template #title>
      <icon icon="example" />
      <span v-if="!isCollapse"> {{ generateTitle('help_docs') }}</span>
    </template>

    <el-menu-item-group>
      <el-menu-item index="Help-Docs" class="submenu-item">
        <a href="https://docs.locoia.com/" target="_blank" rel="noopener" class="w-100">
          <icon icon="documentation" />
          {{ generateTitle('documentation') }}
        </a>
      </el-menu-item>

      <el-menu-item index="Help-YouTube" class="submenu-item">
        <a href="https://www.youtube.com/channel/UC4WaSwSERFd3K-v7-sik4fw" target="_blank" rel="noopener" class="w-100">
          <icon el-icon="ElIconVideoCamera" />
          {{ generateTitle('YouTube') }}
        </a>
      </el-menu-item>

      <el-menu-item
        index="Help-Shortcuts"
        class="submenu-item"
        @click="$emit('eventClick', 'shortcut')"
      >
        <icon el-icon="ElIconInfoFilled" />
        <template #title>
          {{ generateTitle('Shortcuts') }}
        </template>
      </el-menu-item>

      <el-menu-item index="Help-Slack" class="submenu-item">
        <a href="https://join.slack.com/t/locoia-automation/shared_invite/zt-xnphvr1d-_eI0E9_1VGCndrBnFyRPzg" target="_blank" rel="noopener" class="w-100">
          <icon icon="peoples" />
          {{ generateTitle('Community Slack') }}
        </a>
      </el-menu-item>

      <el-menu-item index="Help-About" class="submenu-item">
        <a href="https://www.locoia.com" target="_blank" rel="noopener" class="w-100">
          <icon el-icon="ElIconOfficeBuilding" />
          {{ generateTitle('About us') }}
        </a>
      </el-menu-item>

      <el-menu-item index="Help-GTCs" class="submenu-item">
        <a href="https://hubs.ly/Q01bf7tf0" target="_blank" rel="noopener" class="w-100">
          <icon icon="documentation" />
          {{ generateTitle('GTCs') }}
        </a>
      </el-menu-item>
    </el-menu-item-group>
  </el-sub-menu>
</template>

<script>
import { mapState } from 'vuex'
import { generateTitle } from '@/ui/utils/i18n.js'
import { RouteName } from '@/ui/router/consts.js'
import SidebarMenuItem from '@/ui/layout/Sidebar/SidebarMenuItem.vue'

export default {
  name: 'SidebarMenu',
  components: {
    SidebarMenuItem,
  },
  props: {
    isCollapse: {
      type: Boolean,
      default: false,
    },
    indexAbove: {
      type: String,
      default: null,
    },
  },
  emits: [
    'eventClick',
  ],
  setup() {},
  data() {
    return {
      RouteName,
    }
  },
  computed: {
    ...mapState({
      device: (state) => state.app.device,
    }),
  },
  mounted() {
    this.fixBugIniOS()
  },
  methods: {
    generateTitle,
    /** Goes to a route immediately, but only if the sidebar is collapsed.
     * This is required to use on a sub-menu if you want the root menu item to take you to a specific route upon clicking it.
     * It only works when it's collapsed because when it's expanded you can't hover over the item to see the submenus, you NEED to click it. */
    goToRouteByName(name) {
      if (!this.isCollapse) return
      this.$router.push({ name: name })
    },
    // https://github.com/PanJiaChen/vue-element-admin/pull/1152/files
    fixBugIniOS() {
      const $subMenu = this.$refs.subMenu
      if ($subMenu) {
        const { handleMouseleave } = $subMenu
        $subMenu.handleMouseleave = (e) => {
          if (this.device === 'mobile') {
            return
          }
          handleMouseleave(e)
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@use "@/ui/styles/variables" as *;

.submenu-item {
  --el-menu-level: 0;
  --el-menu-level-padding: 0;
  --el-menu-base-level-padding: 0;
}

:deep(.el-sub-menu__icon-arrow) {
  right: 20px !important;
}

.svg-icon {
  margin: 0 14px !important;
}
.el-icon {
  margin: 0 10px !important;
}

:deep(.el-menu-item-group) {
  background-color: $subMenuBg !important;
  .submenu-item:hover {
    background-color: $subMenuHover !important;
  }
}
:deep(.el-menu-item-group__title) {
  padding: 0
}
.el-menu-item :deep( .el-tooltip__trigger) {
  padding: 0
}
</style>
