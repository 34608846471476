<template>
  <el-menu-item :index="routeName">
    <el-tooltip
      effect="dark"
      :content="title"
      placement="right"
      :disabled="!isMenuCollapsed"
    >
      <router-link :to="getTagRouteLocation(routeName)" class="el-menu-tooltip__trigger w-100">
        <slot name="icon" />
        <span v-if="!isMenuCollapsed">{{ title }}</span>
      </router-link>
    </el-tooltip>
  </el-menu-item>
</template>

<script>
import { useTagsNavigation } from '@/ui/composables/navigation.js'

export default {
  name: 'SidebarMenuItem',
  props: {
    isMenuCollapsed: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    routeName: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { getTagRouteLocation } = useTagsNavigation()
    return {
      getTagRouteLocation,
    }
  },
}
</script>
